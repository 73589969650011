<template>
  <div class="home">
    <h2>OAuth Client List View Page</h2>
    <div v-if="error" class="err-msg">{{ error }}</div>
    <div v-if="loading">Loading...</div>
    <div v-else class="list-view-nav">
      <router-link class="custom-btn" to="/oauth-client/create">
        Create
      </router-link>
    </div>
    <table class="list-view">
      <tr>
        <th>Client ID</th>
        <th>Client Secret</th>
        <th>Actions</th>
      </tr>
      <tr v-for="oauthClient in oauthClients" :key="oauthClient.id">
        <td>{{ oauthClient.client_id }}</td>
        <td>{{ oauthClient.secret }}</td>
        <td>
          <router-link
            class="table-btn"
            :to="`/oauth-client/${oauthClient.id}`"
          >
            Edit
          </router-link>
          <a
            href="#"
            class="table-btn"
            @click="deleteOauthClient(oauthClient.id)"
          >
            Delete
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue, Options } from "vue-class-component";
import { OauthClient } from "../../types/index";

@Options({
  // Define component options here
  // props: {
  //   ...
  // },
})
class OauthClientComponent extends Vue {
  oauthClients: OauthClient[] = [];
  loading: boolean = true;
  error: string = "";
  created() {
    this.getOauthClients().then(() => {
      this.loading = false;
    });
  }
  async getOauthClients() {
    try {
      const response: AxiosResponse<{ data: OauthClient[] }> =
        await apiFetch.oauthClient.getOauthClientsList();
      this.oauthClients = response?.data?.data;
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
  async deleteOauthClient(id: number) {
    try {
      await apiFetch.oauthClient.deleteOauthClient(id);
      await this.getOauthClients();
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
}
export default OauthClientComponent;
</script>
